import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/colors';
import MEDIA from 'styles/media';

import { ImageMonthlyPromo } from './components/ImageMonthlyPromo';
import { TextMonthlyPromo } from './components/TextMonthlyPromo';

const SWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(400px, 450px) auto;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  border: 3px double ${COLORS.CREAMY};

  ${MEDIA.max768`
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    padding-top: 20px;
  `};
`;

const SImageCol = styled.div`
  ${MEDIA.max768`
    display: none;
  `};
`;

export const BannerMonthlyPromo = () => {
  return (
    <SWrapper>
      <SImageCol>
        <ImageMonthlyPromo />
      </SImageCol>
      <TextMonthlyPromo />
    </SWrapper>
  );
};
