import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/button';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import MEDIA from 'styles/media';

const STextMonthlyPromo = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 100px;

  ${MEDIA.max1024`
    padding: 0 20px;
  `};
`;

const SHeader = styled.h2`
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const SSubHeader = styled.p`
  font-size: 18px;
  margin-bottom: 10px;
`;

const SText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`;

const SButton = styled(Button)`
  margin-top: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

const SList = styled.ul`
  margin: 0;
  list-style-type: circle;
  text-align: left;
  list-style-position: outside;
`;
const SListItem = styled.li``;

export const TextMonthlyPromo = () => {
  return (
    <STextMonthlyPromo>
      <SHeader>
        TRWA PROMOCJA na MEZOTERAPIĘ!
      </SHeader>
      <SSubHeader>
        Sprawdź dostępne terminy i umów się na bezpłatną konsultację
        kwalifikującą do zabiegu.
      </SSubHeader>
      <SButton
        text="Zarezerwuj wizytę"
        target="_blank"
        href={EXTERNAL_LINKS.BOOKSY_BOOKING_APP}
        on
      />
      <SText>
        W październiku zabieg mezoterapii neauvia oraz zabiegi mezoterapii pod oczy z wykorzystaniem aminokwasów wykonamy w promocyjnej cenie:
      </SText>
      <SList>
        <SListItem>
          <strong>550 zł</strong> zamiast 700 zł!
        </SListItem>
      </SList>
      <SText>
        Zabiegi są przeznaczone dla wszystkich tych, którzy chcą:
      </SText>
      <SList>
        <SListItem>zniwelować zmarszczki</SListItem>
        <SListItem>poprawić jędrność i gęstość skóry</SListItem>
        <SListItem>nawilżyć skórę</SListItem>
        <SListItem>uzupełnić utraconą objętość</SListItem>
      </SList>
      <SText>
        Szczegółowe informacje dot. produktu dostępne telefonicznie pod ☎️ tel.
        661 719 698.
      </SText>
      <SText>
        Po 14 dniach odbędzie się  <strong>bezpłatna kontrola</strong>.
        Wybieraj bezpieczne i sprawdzone rozwiązania. W naszej klinice pracujemy
        tylko na legalnych medycznych produktach.
      </SText>
      <SText>
        ZAPISZ SIĘ na wizytę kwalifikującą do zabiegu już dziś. Zapraszamy!
      </SText>
    </STextMonthlyPromo>
  );
};
